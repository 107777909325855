import React from 'react';
import dynamic from 'next/dynamic';

import type { ContentfulComponentType, PageDataProps } from '@/types';

const dynamicComponents: any = {
  ComponentHero: {
    Callout: dynamic(() => import('./ComponentHero/ComponentCalloutHero'), {
      suspense: true,
    }),
    Homepage: dynamic(() => import('./ComponentHero/ComponentHomepageHero'), {
      suspense: true,
    }),
    Standard: dynamic(() => import('./ComponentHero/ComponentStandardHero'), {
      suspense: true,
    }),
    Solutions: dynamic(() => import('./ComponentHero/ComponentSolutionsHero'), {
      suspense: true,
    }),
    Insights: dynamic(() => import('./ComponentHero/ComponentInsightsHero'), {
      suspense: true,
    }),
    Article: dynamic(() => import('./ComponentHero/ComponentArticleHero'), {
      suspense: true,
    }),
  },
  ComponentIntroduction: dynamic(
    () => import('./ComponentIntroduction/ComponentIntroduction')
  ),
  ComponentMarketoBlock: {
    Subscribe: dynamic(
      () => import('./ComponentMarketoBlock/ComponentNewsletterSignup'),
      {
        suspense: true,
      }
    ),
    Enquiry: dynamic(
      () => import('./ComponentMarketoBlock/ComponentMarketoBlock'),
      {
        suspense: true,
      }
    ),
  },
  ComponentSimpleGallery: dynamic(
    () => import('./ComponentMediaGallery/ComponentMediaGallery'),
    { suspense: true }
  ),
  ComponentMultiCardCallout: dynamic(
    () => import('./ComponentMultiCardCallout/ComponentMultiCardCallout')
  ),
  ComponentFeaturesItems: dynamic(
    () => import('./ComponentFeaturedItems/ComponentFeaturedItems')
  ),
  ComponentCardListing: dynamic(
    () => import('./ComponentCardListing/ComponentCardListing')
  ),
  ComponentRichText: dynamic(
    () => import('./ComponentRichTextBody/ComponentRichTextBody')
  ),
  ComponentFiftyFiftyCallout: dynamic(
    () => import('./ComponentFiftyFiftyCallout/ComponentFiftyFiftyCallout')
  ),
  ComponentTabbedContent: dynamic(
    () => import('./ComponentFeatureTabs/ComponentFeatureTabs'),
    { suspense: true }
  ),
  ComponentTestimonialCarousel: dynamic(
    () => import('./ComponentTestimonialCarousel/ComponentTestimonialCarousel')
  ),
  ComponentLatestInsightsCallout: dynamic(
    () => import('./ComponentInsightListing/ComponentInsightListing')
  ),
  ComponentArticleContent: dynamic(
    () => import('./ComponentArticleContent/ComponentArticleContent')
  ),
  ComponentEmployeeBlock: dynamic(
    () => import('./ComponentEmployeeBlock/ComponentEmployeeBlock')
  ),
  ComponentBannerCarousel: dynamic(
    () => import('./ComponentBannerCarousel/ComponentBannerCarousel')
  ),
  ComponentHeroCarousel: dynamic(
    () => import('./ComponentHeroCarousel/ComponentHeroCarousel')
  ),
  DataArticleAnchor: dynamic(
    () => import('./DataArticleAnchor/DataArticleAnchor')
  ),
};

export const mapComponent = (
  component: ContentfulComponentType,
  pageData: PageDataProps = {}
) => {
  if (!component) return;

  const { __typename: typeName, sys: { id: entryId } = { id: '' } } = component;
  const uniqueID = Math.floor(Math.random() * Date.now());

  if (
    typeName &&
    Object.keys(component).filter((k: string) => k !== '__typename').length > 0
  ) {
    let DynamicComponent = null;

    if ('heroStyle' in component) {
      DynamicComponent = dynamicComponents[typeName][component.heroStyle];
    } else if ('marketoType' in component) {
      DynamicComponent = dynamicComponents[typeName][component.marketoType];
    } else {
      DynamicComponent = dynamicComponents[typeName];
    }

    if (DynamicComponent) {
      return (
        <React.Fragment key={`component-${entryId}-${uniqueID}`}>
          <DynamicComponent {...component} pageData={pageData} />
        </React.Fragment>
      );
    }
  } else {
    console.log(
      `Warning: Unable to find React component: ${component.__typename}`
    );
  }

  return null;
};
