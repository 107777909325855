import styles from './ComponentCtaButton.module.scss';
import Link from 'next/link';
import { useState } from 'react';
import { Icon, MarketoForm, Modal, trackGA } from '@/ui';
import { useFullPath } from '@/ui/hooks';
import { ComponentCta } from '@/types';
import useDownloader from 'react-use-downloader';

const ComponentCtaButton = ({
  ctaType = 'button',
  ctaText = '',
  ctaStyle = 'outline',
  ctaIconPosition = '',
  ctaIcon = '',
  ctaSize = '',
  ctaUrl = '',
  ctaUrlParams,
  ctaPage,
  ctaMarketoFormId,
  ctaDigitalAsset,
  cssClass = '',
  buttonType = 'button',
  onClick,
  tracking = {},
  id = '',
}: ComponentCta) => {
  const [isOpen, setIsOpen] = useState(false);
  const { download } = useDownloader();
  const hasDownload = ctaDigitalAsset?.url;

  let downloadUrl: string = '';
  let filename: string = '';

  if (ctaIcon == 'Download') {
    ctaType = 'download';
  }

  if (hasDownload) {
    downloadUrl = `/api/asset-redirect?url=${encodeURIComponent(ctaDigitalAsset.url)}`;
    filename = ctaDigitalAsset.fileName;
  }

  let getCtaStyle = ctaStyle
    ? ctaStyle.toLowerCase().replace(' ', '-')
    : ctaStyle;

  let getCtaIcon = ctaIcon ? ctaIcon.toLowerCase() : ctaIcon;

  let getCtaIconPos = ctaIconPosition
    ? ctaIconPosition.toLowerCase()
    : ctaIconPosition;

  const btnLinkUrl = useFullPath(ctaUrl ? ctaUrl : ctaPage?.slug) as string;

  const isLinkButton = ctaType === 'link';
  const isDownloadButton = ctaType === 'download';
  const isJSButton = btnLinkUrl.startsWith('javascript:');

  const handleFormClose = () => {
    setIsOpen(false);
  };

  const handleOpenForm = () => {
    setIsOpen(true);
  };

  const handleTracking = () => {
    if (Object.keys(tracking).length) {
      let event = Object.keys(tracking)[0];

      const gtm_event_obj = {
        title: tracking[event],
      };

      trackGA(event, gtm_event_obj);
    } else if (isDownloadButton) {
      trackGA('file_download', {
        title: ctaText,
        path: downloadUrl ? downloadUrl : btnLinkUrl,
      });
    }
  };

  const handleDownload = (e:any) => {
    e.preventDefault();
    if (tracking) {
      handleTracking();
    }
    if (downloadUrl) {
      download(downloadUrl, filename);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (tracking) {
      handleTracking();
    }

    if (ctaUrl) {
      if (ctaUrl.startsWith('http')) {
        window.open(ctaUrl, '_blank');
      } else {
        window.location.href = ctaUrl;
      }
    } else if (ctaPage?.slug) {
      window.location.href = ctaPage?.slug;
    } else if (ctaMarketoFormId) {
      handleOpenForm();
      trackGA('marketo_open', { title: ctaMarketoFormId });
    } else {
      if (onClick) onClick(e);
    }
  };

  const buttonClasses = [
    styles.btn,
    styles[`btn__${getCtaStyle}`],
    ctaSize ? styles[`btn__${ctaSize}`] : '',
    !getCtaIcon ? styles['btn__no-icon'] : '',
    getCtaIcon === 'arrow'
      ? `${styles['btn__icon']} ${styles[getCtaIcon]}`
      : '',
    getCtaIconPos ? styles[`btn__icon_${getCtaIconPos}`] : '',
    ...cssClass.split(' ').map((className) => styles[className]),
  ];

  const buttonClassName = buttonClasses.filter(Boolean).join(' ');

  if (isDownloadButton && hasDownload) {
    return (
      <a
        href={downloadUrl}
        className={buttonClassName}
        download={filename}
        onClick={handleDownload}
      >
        <span>{ctaText}</span>
        {ctaIcon && <Icon id={getCtaIcon} width={16} height={16} />}
      </a>
    );
  } else if (isJSButton) {
    const scriptToRun = btnLinkUrl.substring('javascript:'.length);
    return (
      <button
        onClick={() => eval(scriptToRun)}
        className={buttonClassName}
        tabIndex={0}
      >
        <span>{ctaText}</span>
        {ctaIcon && <Icon id={getCtaIcon} width={16} height={16} />}
      </button>
    );
  } else if (isLinkButton && btnLinkUrl) {
    const href = ctaUrlParams
      ? { pathname: btnLinkUrl, query: ctaUrlParams }
      : btnLinkUrl;
    return (
      <Link
        href={href}
        className={buttonClassName}
        tabIndex={0}
        target={btnLinkUrl.startsWith('http') ? '_blank' : '_self'}
        onClick={tracking && handleTracking}
        id={id}
      >
        <span>{ctaText}</span>
        {ctaIcon && <Icon id={getCtaIcon} width={16} height={16} />}
      </Link>
    );
  } else if (btnLinkUrl) {
    return (
      <a
        href={btnLinkUrl}
        target='_blank'
        className={buttonClassName}
        onClick={handleTracking}
        id={id}
      >
        <span>{ctaText}</span>
        {ctaIcon && <Icon id={getCtaIcon} width={16} height={16} />}
      </a>
    );
  } else {
    return (
      <>
        <button
          className={buttonClassName}
          onClick={handleClick}
          type={buttonType}
          id={id}
        >
          {ctaText}
          {ctaIcon && <Icon id={getCtaIcon} width={16} height={16} />}
        </button>
        {ctaMarketoFormId && (
          <Modal
            handleClose={() => handleFormClose()}
            isOpen={isOpen}
            triggerId={id}
          >
            <MarketoForm
              key='marketoEnquiry'
              formId={ctaMarketoFormId}
              successMessage='Form submitted'
            />
          </Modal>
        )}
      </>
    );
  }
};

export default ComponentCtaButton;
