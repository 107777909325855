import React, { useContext, useEffect, useState } from 'react';

import type { CalloutHero, ComponentCta } from '@/types';

import { convertMarkdown, useIsMobile, UIContext } from '@/ui/utils';
import { ComponentCtaButton, HeroImageClip, HeroVideoClip } from '@/ui';

import styles from './ComponentCalloutHero.module.scss';

const ComponentCalloutHero = ({
  heroHeading,
  heroMedia,
  heroBody,
  ctas,
  heroColour = '#04aa9e',
  heroSpacing = false,
  isCarouselSlide = false,
}: CalloutHero) => {
  const { playVideoText, pauseVideoText } = useContext(UIContext);

  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const isMobile = useIsMobile();
  const hasMedia = heroMedia?.url;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [heroMedia, isImage]);

  return (
    <div
      // @ts-expect-error: --callout does not exist on style interface.
      style={{ '--callout': `${heroColour}` }}
      className={`
      ${styles.calloutHero} 
      ${!hasMedia && styles.withoutMedia} 
      ${heroSpacing && !isCarouselSlide && styles.withSpacing}
      ${isCarouselSlide && styles.isCarousel}
    `}
    >
      <div
        className={`
          ${styles.calloutHero__container} 
          ${hasMedia && styles.noPaddingRight}
          ${!hasMedia && isCarouselSlide && styles.noPadding}
        `}
      >
        <div className={styles.calloutHero__content}>
          <h2 className={styles.calloutHero__heading}>{heroHeading}</h2>
          {heroBody && (
            <div className={styles.calloutHero__body}>
              {convertMarkdown(heroBody)}
            </div>
          )}
          {hasCtas && (
            <div className={styles.calloutHero__ctas}>
              {ctas.map((cta: ComponentCta) => (
                <ComponentCtaButton
                  key={cta.ctaText}
                  ctaType='link'
                  ctaText={cta.ctaText}
                  ctaAria={cta.ctaAria}
                  ctaStyle={cta.ctaStyle}
                  ctaIcon={cta.ctaIcon}
                  ctaIconPosition={cta.ctaIconPosition}
                  ctaUrl={cta.ctaUrl}
                  ctaPage={cta.ctaPage}
                  ctaMarketoFormId={cta.ctaMarketoFormId}
                  ctaDigitalAsset={cta.ctaDigitalAsset}
                />
              ))}
            </div>
          )}
        </div>
        {hasMedia && (
          <div className={styles.calloutHero__image}>
            {isVideo && hasMedia && (
              <button
                type='button'
                className={`${styles.calloutHero__videoButton} ${
                  isPlaying ? styles.pause : styles.play
                }`}
                onClick={togglePlayPause}
              >
                {!isMobile && (isPlaying ? pauseVideoText : playVideoText)}
              </button>
            )}

            {isImage && <HeroImageClip heroMedia={heroMedia} ovalBottom />}
            {isVideo && (
              <HeroVideoClip
                isPlaying={isPlaying}
                heroMedia={heroMedia}
                ovalBottom
                priority={isCarouselSlide}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentCalloutHero;
