import ReactPlayer from 'react-player/lazy';

import styles from './HeroVideoClip.module.scss';

const HeroVideoClip = ({
  heroMedia,
  isPlaying = true,
  ovalBottom = false,
  priority = false,
}: {
  heroMedia?: any;
  isPlaying?: boolean;
  ovalBottom?: boolean;
  priority?: boolean;
}) => {
  return (
    <div
      className={`${styles.heroVideoClip__clipped} ${
        ovalBottom && styles.mobileBottomOval
      }`}
    >
      <ReactPlayer
        className={`${styles.homepageHero__backgroundMedia}`}
        url={heroMedia?.url}
        width='100%'
        height='100%'
        playing={isPlaying}
        muted
        loop
        playsinline
        controls={false}
      />
    </div>
  );
};

export default HeroVideoClip;
