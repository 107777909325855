import styles from './MarketoForm.module.scss';

import { useContext } from 'react';

import { useMarketo } from '@/ui/hooks';
import { Icon, UIContext, sourceSansPro } from '@/ui';

interface MarketoFormProps {
  formId: string;
  prePopulatedFields?: { [key: string]: string };
  successMessage: string;
}

export const MarketoForm = ({
  formId,
  prePopulatedFields,
  successMessage,
}: MarketoFormProps) => {
  const { marketoMunchkinId: munchkinId } = useContext(UIContext);
  useMarketo({ munchkinId, formId, prePopulatedFields });

  return (
    <>
      <div id='marketoLoader' className={styles.marketoForm__loading}>
        <Icon id='loading' width={44} height={44} />
      </div>

      <form
        id={`mktoForm_${formId}`}
        className={`${styles.marketoForm} ${sourceSansPro.className}`}
      />

      <div
        className={styles.marketoForm__success}
        style={{ display: 'none' }}
        id={`success-${formId}`}
      >
        {successMessage}
      </div>
    </>
  );
};
