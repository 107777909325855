import {
  componentIntroductionFields,
  componentHeroFields,
  componentMultiCardCalloutFields,
  componentFeaturedItemsFields,
  componentCardListingFields,
  componentRichTextBodyFields,
  componentFiftyFiftyCalloutFields,
  componentTestimonialCarouselFields,
  componentBannerCarouselFields,
  componentHeroCarouselFields,
  componentMarketoBlockFields,
  componentMediaGalleryFields,
  componentLatestInsightsCallout,
  componentFeatureTabsFields,
  componentArticleContent,
  componentEmployeeBlockFields,
  dataArticleAnchorFields
} from '@/intergrations';

type componentFieldsMapType = {
  [typename: string]: string;
};

export function componentFieldsMapped(typename: string) {
  // Define a map of component typenames to their respective fields
  const componentFieldsMap: componentFieldsMapType = {
    ComponentIntroduction: componentIntroductionFields,
    ComponentHero: componentHeroFields,
    ComponentMultiCardCallout: componentMultiCardCalloutFields,
    ComponentFeaturesItems: componentFeaturedItemsFields,
    ComponentCardListing: componentCardListingFields,
    ComponentRichText: componentRichTextBodyFields,
    ComponentFiftyFiftyCallout: componentFiftyFiftyCalloutFields,
    ComponentTestimonialCarousel: componentTestimonialCarouselFields,
    ComponentMarketoBlock: componentMarketoBlockFields,
    ComponentSimpleGallery: componentMediaGalleryFields,
    ComponentLatestInsightsCallout: componentLatestInsightsCallout,
    ComponentTabbedContent: componentFeatureTabsFields,
    ComponentArticleContent: componentArticleContent,
    ComponentEmployeeBlock: componentEmployeeBlockFields,
    ComponentBannerCarousel: componentBannerCarouselFields,
    ComponentHeroCarousel: componentHeroCarouselFields,
    DataArticleAnchor: dataArticleAnchorFields,
  };

  return componentFieldsMap[typename];
}
