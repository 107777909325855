import styles from './ComponentFeaturedItems.module.scss';

import {
  ComponentCtaButton,
  RelatedInsightWrapper,
  ResponsiveImage,
  getRelatedInsightsWrapperProps,
} from '@/ui';
import { convertMarkdown } from '@/ui/utils';
import { FeaturedItemsComponent, ImageHeadingBodyItem } from '@/types';
import { useEffect, useState } from 'react';

const ComponentFeaturedItems = ({
  informationListingHeading,
  informationListingStyle,
  informationListingKeyline,
  informationListingItems,
  relatedInsight,
}: FeaturedItemsComponent) => {
  const layoutStyle = `layout${informationListingStyle.replaceAll(' ', '')}`;
  const keyline = `keyline_${informationListingKeyline?.toLowerCase()}`;

  const [horizontal, setHorizontal] = useState(false);

  useEffect(() => {
    if (keyline === 'keyline_horizontal') {
      setHorizontal(true);
    }
  }, [keyline]);

  const relatedInsightsWrapperProps = getRelatedInsightsWrapperProps({
    component: relatedInsight,
    headingVariant: 'h3',
  });

  return (
    <section
      className={`${styles.featuredItems} ${
        relatedInsight ? styles.hasRelatedInsights : ''
      }`}
    >
      <h2 className={styles.featuredItems__heading}>
        {informationListingHeading}
      </h2>
      <div className={styles.featuredItems__content}>
        <div className={`${styles.featuredItems__grid} ${styles[layoutStyle]}`}>
          {informationListingItems.map(
            (item: ImageHeadingBodyItem, index: number) => {
              const { ihbHeading, ihbBody, ihbImage, ihbCta } = item;
              if (!ihbHeading && !ihbBody) return null;

              return (
                <div
                  className={`${styles.featuredItems__item} ${styles[keyline]}`}
                  key={`featuredItem_${item.sys?.id}_${index}_key`}
                >
                  {!!ihbImage && horizontal && (
                    <div className={styles.featuredItems__itemImageWrapper}>
                      <ResponsiveImage
                        media={ihbImage}
                        sizes={{ sm: 160 }}
                        className={styles.featuredItems__itemImage}
                      />
                    </div>
                  )}
                  <div className={styles.featuredItems__itemHeadingWrapper}>
                    {!!ihbHeading && !ihbCta && (
                      <h3 className={styles.featuredItems__itemHeading}>
                        {ihbHeading}
                      </h3>
                    )}
                    {!!ihbCta && horizontal && (
                      <ComponentCtaButton
                        ctaType='link'
                        ctaStyle='external'
                        ctaText={ihbHeading}
                        ctaIcon='external'
                        ctaIconPosition='right'
                        ctaUrl={ihbCta.ctaUrl}
                        cssClass={styles.featuredItems__itemCta}
                      />
                    )}
                  </div>

                  {!!ihbBody && convertMarkdown(ihbBody)}
                </div>
              );
            }
          )}
        </div>

        {!!relatedInsightsWrapperProps && (
          <div className={styles.featuredItems__relatedInsights}>
            <RelatedInsightWrapper {...relatedInsightsWrapperProps} />
          </div>
        )}
      </div>
    </section>
  );
};

export default ComponentFeaturedItems;
