import styles from './MediaCard.module.scss';

import ReactPlayer from 'react-player/lazy';
import { useState } from 'react';

import { DataDigitalAsset } from '@/types';

import { ResponsiveImage, Modal, Icon } from '@/ui';
import { colorPickerHexToClassName } from '@/ui/constants';

const returnMediaType = (path = '') => {
  if (!path) return '';

  return path.includes('https://videos.ctfassets.net') ||
    path.includes('youtube.com/watch?v=') ||
    path.includes('player.vimeo')
    ? 'video'
    : 'image';
};

export const MediaCard = ({
  sys,
  assetSourceLocal,
  assetSourceRemote,
  videoThumbnail,
  videoLength,
  assetDescription,
  pillarColour,
  enableLightbox = false,
}: DataDigitalAsset) => {
  const lightBox =
    enableLightbox && returnMediaType(assetSourceLocal?.url) === 'image';
  const [isPlaying, setIsPlaying] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const isRemoteSource = !!assetSourceRemote;
  const mediaType = returnMediaType(
    isRemoteSource ? assetSourceRemote : assetSourceLocal?.url
  );
  const id = `media-${sys.id}`;

  const [videoIsLoading, setVideoIsLoading] = useState(true);

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleImageClick = () => {
    if (lightBox) {
      setIsImageOpen(true);
    }
  };

  const handleOnReady = () => {
    setVideoIsLoading(false);
  };

  const styleColour = pillarColour
    ? colorPickerHexToClassName[pillarColour]
    : '';

  if ((mediaType === 'video' && !videoThumbnail) || mediaType === '') {
    return null;
  }

  let media;

  if (isRemoteSource) {
    media = mediaType === 'video' ? videoThumbnail : { url: assetSourceRemote };
  } else {
    media = mediaType === 'video' ? videoThumbnail : assetSourceLocal;
  }

  return (
    <div className={styles.mediaCard}>
      <div
        className={`${styles.mediaCard__media} ${
          lightBox ? styles.mediaCard__lightbox : ''
        }`}
      >
        <ResponsiveImage
          sizes={{ sm: 400, md: 845, lg: 1024 }}
          className={styles.mediaCard__image}
          media={media}
          onClick={lightBox ? handleImageClick : undefined}
        />
        {mediaType === 'video' && (
          <>
            <button
              type='button'
              onClick={handleVideoPlay}
              className={styles.mediaCard__playButton}
              id={id}
            >
              <Icon
                className={styles.mediaCard__playIcon}
                id='play'
                width={40}
                height={40}
              />
            </button>
            <Modal
              handleClose={() => setIsPlaying(false)}
              isOpen={isPlaying}
              triggerId={id}
            >
              <div className={styles.mediaCard__videoContainer}>
                {videoIsLoading && (
                  <Icon
                    className={styles.mediaCard__loading}
                    id='loading'
                    width={44}
                    height={44}
                  />
                )}
                <ReactPlayer
                  className={styles.mediaCard__videoEl}
                  url={
                    isRemoteSource ? assetSourceRemote : assetSourceLocal?.url
                  }
                  width='100%'
                  height='100%'
                  playing={isPlaying}
                  controls
                  onReady={() => handleOnReady}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
      {assetDescription && (
        <div className={`${styles.mediaCard__caption} ${styles[styleColour]}`}>
          {videoLength && (
            <span className={styles.mediaCard__videoLength}>{videoLength}</span>
          )}
          {assetDescription}
        </div>
      )}
      {lightBox && (
        <Modal
          handleClose={() => setIsImageOpen(false)}
          isOpen={isImageOpen}
          triggerId={id}
          fullContent
          isLightBox
        >
          <div className={styles.mediaCard__lightboxContainer}>
            <div className={'loading'}>
              <Icon
                id='loading'
                className={`${styles.loadingIcon}`}
                width={44}
                height={44}
              />
            </div>
            <ResponsiveImage
              sizes={{ sm: 1600, md: 1600, lg: 1600 }}
              className={styles.mediaCard__lightboxModalImage}
              media={media}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MediaCard;
